@import url("https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,100..900;1,100..900&display=swap");

#gsChartExport #pdp__gs__chart {
  border: 0 !important;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

#gsChartExport #pdp__gs__chart::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.chart-heading {
  color: #2c7765;
  font-family: "Chivo", sans-serif;
  font-weight: 900;
  line-height: 1.25;
  &.xs {
    font-size: 14px;
  }
  &.sm {
    font-size: 18px;
  }
  &.md {
    font-size: 24px;
  }
  &.lg {
    font-size: 32px;
  }
  .copyright-icon {
    font-size: 14px;
    top: 0;
  }
}

@media (max-width: 992px) {
  .chart-heading {
    &.lg {
      font-size: 24px;
    }
    & .copyright-icon {
      font-size: 12px;
    }
  }
}
