.gs-radial-wrap {
  .ant-progress-text {
    padding-top: 1px !important;
  }
  &.lg {
    max-width: 95px;
    & .gs-feel-text {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 600;
    }
    & .gs-text {
      font-size: 14.5px;
      line-height: 1.25;
    }
    & .ant-progress-inner {
      width: 70px !important;
      height: 70px !important;
      font-size: 1.25rem !important;
    }

    @media (min-width: 768px) and (max-width: 1120px) {
      max-width: 85px;
      & .ant-progress-text {
        font-size: 14px;
      }
      & .gs-feel-text {
        font-size: 14px;
        font-weight: 600;
      }
      & .gs-text {
        font-size: 13.25px;
        line-height: 1.25;
      }
      & .ant-progress-inner {
        width: 56px !important;
        height: 56px !important;
        font-size: 0.75rem !important;
      }
    }

    @media (min-width: 100px) and (max-width: 767px) {
      max-width: 75px;
      & .gs-feel-text {
        font-weight: 600;
        font-size: 13px;
      }
      & .gs-text {
        font-size: 12px;
      }
      & .ant-progress-inner {
        width: 40px !important;
        height: 40px !important;
        font-size: 0.75rem !important;
      }
    }
  }

  &.md {
    max-width: 82px;
    & .gs-feel-text {
      font-size: 13px;
      line-height: 1.25;
      font-weight: 600;
    }
    & .gs-text {
      font-size: 12.5px;
      line-height: 1.25;
    }
    & .ant-progress-inner {
      width: 56px !important;
      height: 56px !important;
      font-size: 1rem !important;
    }

    @media (min-width: 100px) and (max-width: 767px) {
      max-width: 68px;

      & .gs-feel-text {
        font-size: 12px;
        font-weight: 600;
      }
      & .gs-text {
        font-size: 11px;
      }
      & .ant-progress-inner {
        width: 40px !important;
        height: 40px !important;
        font-size: 0.75rem !important;
      }
    }
  }

  &.sm {
    max-width: 65px;
    & .gs-feel-text {
      font-size: 11px;
      line-height: 1.25;
      font-weight: 600;
    }
    & .gs-text {
      font-size: 10.25px;
      line-height: 1.25;
    }
    & .ant-progress-inner {
      width: 40px !important;
      height: 40px !important;
      font-size: 0.85rem !important;
    }
  }
}

.gs-link-txt {
  color: #54aa95;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.copyright-icon {
  font-size: 8px;
  bottom: -0.4px;
  line-height: 1;

  @media (max-width: 767px) {
    font-size: 7px;
  }
}
