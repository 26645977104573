#product-demo #gs-radial {
  width: 110px;
  padding: 0 10px;
  height: 110px;
}

#product-demo .chart-wrap {
  width: 100%;
  height: 100%;
}

#product-demo #gs-chart {
  width: 100%;
  min-height: 625px;
}

#product-demo #bfy-carousel {
  width: 100%;
  min-height: 400px;
}

.main-img {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.main-img img {
  max-height: 500px;
  object-fit: contain;
}

@media (max-width: 767px) {
  .main-img img {
    max-height: 350px;
    object-fit: contain;
  }

  #product-demo .chart-wrap {
    width: 98%;
  }

  #product-demo #gs-chart {
    height: 700px;
    overflow-y: scroll;
  }

  #product-demo #bfy-carousel {
    margin-top: 1.75rem;
  }
}
